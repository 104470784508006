var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-dialog",
    {
      attrs: {
        value: _vm.value,
        persistent: "",
        width: _vm.layoutCreated ? 1000 : 500,
      },
    },
    [
      _c(
        "v-card",
        { attrs: { color: "fill" } },
        [
          _c(
            "v-card-title",
            { staticClass: "accent--text font-weight-light" },
            [
              _vm._v(
                " " +
                  _vm._s(
                    _vm.isExistingLayout ? "Layout Builder" : "Create Layout"
                  ) +
                  " "
              ),
              _c("v-spacer"),
              _vm.isExistingLayout
                ? _c(
                    "v-menu",
                    {
                      attrs: {
                        "offset-y": "",
                        "offset-x": "",
                        left: "",
                        bottom: "",
                        "nudge-right": 5,
                        "nudge-top": 5,
                      },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on, attrs }) {
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "white--text",
                                        attrs: {
                                          small: "",
                                          icon: "",
                                          color: "delete",
                                        },
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [_c("v-icon", [_vm._v("mdi-trash-can")])],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2106085849
                      ),
                    },
                    [
                      _c(
                        "v-card",
                        { staticClass: "pa-6" },
                        [
                          _c(
                            "v-row",
                            {
                              staticClass: "py-4",
                              attrs: { justify: "center" },
                            },
                            [_c("span", [_vm._v("Delete Layout?")])]
                          ),
                          _c(
                            "v-row",
                            [
                              _c(
                                "v-btn",
                                { class: _vm.body, attrs: { text: "" } },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                "v-btn",
                                {
                                  staticClass: "primary white--text ml-2",
                                  class: _vm.body,
                                  on: { click: _vm.deleteLayout },
                                },
                                [_vm._v("Confirm")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _c(
            "v-container",
            {
              directives: [
                {
                  name: "resize",
                  rawName: "v-resize",
                  value: _vm.onResize,
                  expression: "onResize",
                },
              ],
              staticClass: "ma-0 pa-4",
            },
            [
              _c(
                "v-form",
                { ref: "form" },
                [
                  _c(
                    "v-row",
                    {
                      staticStyle: { height: "50px" },
                      attrs: { align: "center", dense: "" },
                    },
                    [
                      _c(
                        "v-col",
                        { attrs: { cols: _vm.layoutCreated ? 5 : 12 } },
                        [
                          _c("v-text-field", {
                            class: _vm.body,
                            attrs: {
                              autofocus: "",
                              "background-color": "#fff",
                              dense: "",
                              outlined: "",
                              label: "Layout Name",
                              autocomplete: "off",
                              rules: [(v) => !!v || "Layout Name Required"],
                            },
                            model: {
                              value: _vm.layout.ad_name,
                              callback: function ($$v) {
                                _vm.$set(_vm.layout, "ad_name", $$v)
                              },
                              expression: "layout.ad_name",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm.layoutCreated
            ? _c(
                "v-container",
                { staticClass: "pa-1", attrs: { fluid: "" } },
                [
                  _c(
                    "v-row",
                    { attrs: { dense: "" } },
                    [
                      _c(
                        "v-col",
                        {
                          staticClass: "pt-1 pb-0 px-0",
                          attrs: { md: "3", lg: "3", xl: "3" },
                        },
                        [
                          _c(
                            "v-toolbar",
                            {
                              staticClass: "accent white--text",
                              attrs: { height: "32", flat: "" },
                            },
                            [
                              _c(
                                "v-toolbar-title",
                                {
                                  staticClass: "text-caption font-weight-bold",
                                },
                                [_vm._v("Pages")]
                              ),
                            ],
                            1
                          ),
                          _c(
                            "v-tabs",
                            {
                              staticClass: "pt-0",
                              style: _vm.tabsHeight,
                              attrs: {
                                vertical: "",
                                "background-color": "fill",
                                color: "primary",
                                light: "",
                                "hide-slider": "",
                              },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            _vm._l(_vm.pages, function (page, index) {
                              return _c(
                                "v-tab",
                                {
                                  key: index,
                                  staticClass:
                                    "text-capitalize font-weight-normal text-body-2",
                                  on: {
                                    change: function ($event) {
                                      _vm.adPage = page
                                    },
                                  },
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        _vm.adPage === page
                                          ? "font-weight-bold"
                                          : "font-weight-normal",
                                    },
                                    [_vm._v(" " + _vm._s(page.name))]
                                  ),
                                  _c("v-spacer"),
                                  _vm.adPage === page
                                    ? _c("v-icon", [
                                        _vm._v("mdi-chevron-right"),
                                      ])
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "v-col",
                        { staticClass: "mt-1 pt-0 px-0", style: _vm.colBorder },
                        [
                          _c("v-data-table", {
                            staticClass: "pa-0 ma-0",
                            class: _vm.table,
                            attrs: {
                              disabled: "",
                              headers: _vm.headers,
                              "items-per-page": -1,
                              items: _vm.pagePositions,
                              search: _vm.search,
                              loading: _vm.loading,
                              "item-key": "id",
                              "show-select": "",
                              dense: "",
                              "fixed-header": "",
                              height: _vm.tableHeight,
                              "hide-default-footer": "",
                              "item-class": _vm.itemRowBackground,
                            },
                            on: {
                              "update:items": function ($event) {
                                _vm.pagePositions = $event
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: `header.data-table-select`,
                                  fn: function ({ on, props }) {
                                    return [
                                      _c(
                                        "v-simple-checkbox",
                                        _vm._g(
                                          _vm._b(
                                            {
                                              attrs: {
                                                ripple: false,
                                                dark: "",
                                              },
                                            },
                                            "v-simple-checkbox",
                                            props,
                                            false
                                          ),
                                          on
                                        )
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              true
                            ),
                            model: {
                              value: _vm.selected,
                              callback: function ($$v) {
                                _vm.selected = $$v
                              },
                              expression: "selected",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm.isExistingLayout ? _c("v-divider") : _vm._e(),
          _c(
            "v-toolbar",
            { attrs: { flat: "", color: "fill" } },
            [
              _c("v-spacer"),
              _c(
                "v-btn",
                {
                  class: _vm.body,
                  attrs: { text: "" },
                  on: {
                    click: function ($event) {
                      return _vm.$emit("update:value", false)
                    },
                  },
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.isExistingLayout ? "Close" : "Cancel") +
                      " "
                  ),
                ]
              ),
              !_vm.isExistingLayout
                ? _c(
                    "v-btn",
                    {
                      staticClass: "save white--text ml-2",
                      class: _vm.body,
                      on: {
                        click: function ($event) {
                          _vm.isExistingLayout
                            ? _vm.updateLayout()
                            : _vm.createLayout()
                        },
                      },
                    },
                    [
                      _vm._v(
                        " " +
                          _vm._s(_vm.isExistingLayout ? "Update" : "Save") +
                          " "
                      ),
                    ]
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }